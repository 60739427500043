import { lazy } from "react";
const EditUserPage = lazy(() => import("./pages/user-management/edit"));
const BookingManagementPage = lazy(() => import("./pages/booking-management"));
const BookingDetailsPage = lazy(() =>
  import("./pages/booking-management/details")
);
const AddBookingPage = lazy(() => import("./pages/booking-management/add"));
const PaymentPage = lazy(() => import("./pages/payment"));
const ReportsPage = lazy(() => import("./pages/reports"));
const CustomerManagementPage = lazy(() =>
  import("./pages/customer-management")
);
const CustomerDetailsPage = lazy(() =>
  import("./pages/customer-management/details")
);
const UserManagementPage = lazy(() => import("./pages/user-management"));
const AddUserPage = lazy(() => import("./pages/user-management/add"));
const UserDetailsPage = lazy(() => import("./pages/user-management/details"));
const EditProfilePage = lazy(() => import("./pages/auth/edit-profile"));
const SubscriptionPage = lazy(() => import("./pages/subscription"));
const BranchManagementPage = lazy(() => import("./pages/branch-management"));
const AddBranchPage = lazy(() => import("./pages/branch-management/add"));
const EditBranchPage = lazy(() => import("./pages/branch-management/edit"));
const BranchDetailsPage = lazy(() =>
  import("./pages/branch-management/details")
);
const ResetPasswordPage = lazy(() => import("./pages/auth/reset-password"));
const NotFoundPage = lazy(() => import("./pages/not-found"));
const DashboardPage = lazy(() => import("./pages/dashboard"));
const LockScreenPage = lazy(() => import("./pages/auth/lock-screen"));
const LoginPage = lazy(() => import("./pages/auth/login"));
// const AuditLogsPage = lazy(() => import("./pages/audit-logs"));
const ProfilePage = lazy(() => import("./pages/auth/profile"));
const RolesAndPermissionsPage = lazy(() =>
  import("./pages/system-management/roles-and-permissions")
);

const routes = [
  {
    title: "Login",
    path: "/",

    isProtected: false,
    element: (props) => <LoginPage {...props} />,
  },
  {
    title: "Lock Screen",
    path: "/lock-screen",
    isProtected: false,
    element: (props) => <LockScreenPage {...props} />,
  },
  {
    title: "Reset Password",
    path: "/reset-password",
    isProtected: false,
    element: (props) => <ResetPasswordPage {...props} />,
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    parentPath: "/dashboard",
    isProtected: true,
    permission: "66aa2d71a37e718c79d58ba6",
    element: (props) => <DashboardPage {...props} />,
  },

  // profile
  {
    title: "Profile",
    path: "/profile",
    parentPath: "/profile",
    isProtected: true,
    element: (props) => <ProfilePage {...props} />,
  },
  {
    title: "Edit Profile",
    path: "/profile/edit",
    parentPath: "/profile",
    isProtected: true,
    element: (props) => <EditProfilePage {...props} />,
  },

  // Subscription
  {
    title: "Subscription",
    path: "/subscription",
    parentPath: "/profile",
    isProtected: true,
    permission: "678c3bb4100fbf01b1c8b819",
    runProtection: true,
    element: (props) => <SubscriptionPage {...props} />,
  },

  // branch management
  {
    title: "Branch Management",
    path: "/branch-management",
    parentPath: "/branch-management",
    permission: "66aa2d71a37e718c79d58ba7",
    isProtected: true,
    runProtection: true,
    element: (props) => <BranchManagementPage {...props} />,
  },
  {
    title: "Branch Details",
    path: "/branch-management/details",
    parentPath: "/branch-management",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58ba7",
    element: (props) => <BranchDetailsPage {...props} />,
  },
  {
    title: "Add Branch",
    path: "/branch-management/add",
    parentPath: "/branch-management",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58ba7",
    element: (props) => <AddBranchPage {...props} />,
  },
  {
    title: "Edit Branch",
    path: "/branch-management/edit",
    parentPath: "/branch-management",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58ba7",
    element: (props) => <EditBranchPage {...props} />,
  },

  // Customer management
  {
    title: "Customer Management",
    path: "/customer-management",
    parentPath: "/customer-management",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58ba8",
    element: (props) => <CustomerManagementPage {...props} />,
  },
  {
    title: "Customer Details",
    path: "/customer-management/details",
    parentPath: "/customer-management",
    permission: "66aa2d71a37e718c79d58ba8",
    isProtected: true,
    runProtection: true,
    element: (props) => <CustomerDetailsPage {...props} />,
  },

  // User management
  {
    title: "User Management",
    path: "/user-management",
    parentPath: "/user-management",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58ba9",
    element: (props) => <UserManagementPage {...props} />,
  },
  {
    title: "Add User",
    path: "/user-management/add",
    parentPath: "/user-management",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58ba9",
    element: (props) => <AddUserPage {...props} />,
  },

  {
    title: "Edit User",
    path: "/user-management/edit",
    parentPath: "/user-management",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58ba9",
    element: (props) => <EditUserPage {...props} />,
  },

  {
    title: "User Details",
    path: "/user-management/details",
    parentPath: "/user-management",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58ba9",
    element: (props) => <UserDetailsPage {...props} />,
  },

  {
    title: "Roles and Permissions",
    path: "/roles-and-permissions",
    parentPath: "/roles-and-permissions",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58bad",
    element: (props) => <RolesAndPermissionsPage {...props} />,
  },

  // bookings management
  {
    title: "Bookings Management",
    path: "/booking-management",
    parentPath: "/booking-management",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58baa",
    element: (props) => <BookingManagementPage {...props} />,
  },
  {
    title: "Booking Details",
    path: "/booking-management/details",
    parentPath: "/booking-management",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58baa",
    element: (props) => <BookingDetailsPage {...props} />,
  },

  {
    title: "Add Booking",
    path: "/booking-management/add",
    parentPath: "/booking-management",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58baa",
    element: (props) => <AddBookingPage {...props} />,
  },

  // payments
  {
    title: "Payments",
    path: "/payments",
    parentPath: "/payments",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58bab",
    element: (props) => <PaymentPage {...props} />,
  },

  // Reports
  {
    title: "Reports",
    path: "/reports",
    parentPath: "/reports",
    isProtected: true,
    runProtection: true,
    permission: "66aa2d71a37e718c79d58bac",
    element: (props) => <ReportsPage {...props} />,
  },

  // {
  //   title: "Audit Logs",
  //   path: "/audit-logs",
  //   parentPath: "/audit-logs",
  //   isProtected: true,
  //   runProtection: true,
  //   permission: "66e92c676c00a30007814b0e",
  //   element: (props) => <AuditLogsPage {...props} />,
  // },

  {
    title: "404 Page Not Found",
    path: "*",
    isProtected: false,
    element: (props) => <NotFoundPage {...props} />,
  },
];

export default routes;
